import React, { useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableHead,
  TableContainer,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Card,
  CardContent,
  FormControl,
  InputLabel,
} from '@mui/material';


import { useNavigate } from 'react-router-dom';

const patients = [
    {
      id: 1,
      age: 67,
      height: 165,
      weight: 75,
      sex: 'Male',
      bedType: 'https://healthicons.org/icons/svg/filled/people/outpatient.svg',
      vitals: 'BP: 130/85, HR: 80',
      conditions: 'Hypertension, Chronic Kidney Disease, Carrying Impella',
      specialStatus: 'https://healthicons.org/icons/svg/outline/symbols/virus.svg',
      currentHospital: 'Small Hospital A',
      transferType: "Low to High",
      status: "Critical"
    },
    {
      id: 2,
      age: 52,
      height: 170,
      bedType: 'https://healthicons.org/icons/svg/outline/specialties/intensive_care_unit.svg',
      weight: 85,
      sex: 'Female',
      vitals: 'BP: 140/90, HR: 85',
      conditions: 'Type 2 Diabetes, Obesity, BLS, Bypass Required',
      specialStatus: 'https://healthicons.org/icons/svg/outline/symbols/virus.svg',
      currentHospital: 'Small Hospital B',
      transferType: "Low to High",
      status: "Critical"
    
    },
    {
      id: 3,
      age: 29,
      height: 180,
      weight: 68,
      transferType: "High to Low",
      sex: 'Male',
      vitals: 'BP: 120/80, HR: 72',
      specialStatus: 'https://healthicons.org/icons/svg/outline/specialties/critical_care.svg',
      bedType: 'https://healthicons.org/icons/svg/filled/people/outpatient.svg',
      conditions: 'Asthma, Seasonal Allergies',
      currentHospital: 'Small Hospital C',
      status: "Stable"
    
    },
    {
      id: 4,
      age: 45,
      height: 155,
      weight: 62,
      specialStatus: 'https://healthicons.org/icons/svg/outline/symbols/alert_triangle.svg',
      bedType: 'https://healthicons.org/icons/svg/outline/specialties/intensive_care_unit.svg',
      sex: 'Female',
      vitals: 'BP: 110/70, HR: 75',
      conditions: 'Anemia, Carrying Impella',
      currentHospital: 'Small Hospital D',
      transferType: "Low to High",
      status: "Declining"
    
    },
    {
      id: 5,
      age: 80,
      height: 160,
      weight: 70,
      bedType: 'https://healthicons.org/icons/svg/outline/specialties/intensive_care_unit.svg',
      sex: 'Male',
      vitals: 'BP: 150/95, HR: 90',
      transferType: "High to Low",
      conditions: 'Congestive Heart Failure, Hypertension, ALS',
      specialStatus: 'https://healthicons.org/icons/svg/outline/specialties/critical_care.svg',
      currentHospital: 'Small Hospital E',
      status: "Critical"
    
    },
    {
      id: 6,
      age: 34,
      height: 175,
      weight: 78,
      bedType: 'https://healthicons.org/icons/svg/filled/people/outpatient.svg',
      specialStatus: 'https://healthicons.org/icons/svg/outline/specialties/critical_care.svg',
      sex: 'Female',
      vitals: 'BP: 118/76, HR: 68',
      conditions: 'Depression, Anxiety',
      transferType: "Low to High",
      currentHospital: 'Small Hospital F',
      status: "Declining"
    
    },
    {
      id: 7,
      age: 58,
      height: 168,
      bedType: 'https://healthicons.org/icons/svg/outline/specialties/intensive_care_unit.svg',
      weight: 92,
      specialStatus: 'https://healthicons.org/icons/svg/outline/symbols/alert_triangle.svg',
      transferType: "Low to High",
      sex: 'Male',
      vitals: 'BP: 135/85, HR: 82',
      conditions: 'COPD, Smoker',
      currentHospital: 'Small Hospital G',
      status: "Declining"
    
    },
    {
      id: 8,
      age: 72,
      height: 160,
      weight: 68,
      bedType: 'https://healthicons.org/icons/svg/outline/specialties/intensive_care_unit.svg',
      specialStatus: 'https://healthicons.org/icons/svg/outline/symbols/alert_triangle.svg',
      sex: 'Female',
      transferType: "Low to High",
      vitals: 'BP: 125/80, HR: 74',
      conditions: 'Osteoporosis, Hypertension',
      currentHospital: 'Small Hospital H',
      status: "Critical"
    
    },
    {
      id: 9,
      age: 40,
      height: 182,
      weight: 90,
      sex: 'Male',
      vitals: 'BP: 122/78, HR: 70',
      bedType: 'https://healthicons.org/icons/svg/outline/specialties/intensive_care_unit.svg',
      specialStatus: 'https://healthicons.org/icons/svg/outline/specialties/critical_care.svg',
      conditions: 'Hyperlipidemia, GERD',
      currentHospital: 'Small Hospital I',
      transferType: "High to Low",
      status: "Stable"
    
    },
    {
      id: 10,
      age: 25,
      height: 170,
      weight: 65,
      sex: 'Female',
      vitals: 'BP: 110/68, HR: 65',
      bedType: 'https://healthicons.org/icons/svg/filled/people/outpatient.svg',  
      specialStatus: 'https://healthicons.org/icons/svg/outline/specialties/critical_care.svg',
      conditions: 'Epilepsy, Migraines',
      currentHospital: 'Small Hospital J',
      transferType: "High to Low",
      status: "Stable"
    
    },
  ];
  

const PatientTransferPage = () => {
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [filters, setFilters] = useState({
    age: '',
    sex: '',
    condition: '',
  });
  const [selectedTemplate, setSelectedTemplate] = useState(''); // For dropdown selection

  const navigate = useNavigate();

  const handleSelectPatient = (id) => {
    if (!selectedPatients.includes(id)) {

      setSelectedPatients([...selectedPatients, id]);

    }
    navigate('/transfer-receive');
  
  };

  const handleTemplateChange = (event) => {
    setSelectedTemplate(event.target.value);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleCreateNewListing = () => {
    navigate('/transfer-form');
  };

  const handleCreateFromTemplate = () => {
    // Logic to create a listing from the selected template
    alert(`Creating listing from template: ${selectedTemplate}`);
    
    if(selectedTemplate === 'Cardiac Event'){
      navigate('/transfer-form/4');
 
    }else if (selectedTemplate === "Hip Fracture"){
      navigate('/transfer-form/6');
 
    }else if(selectedTemplate === "Covid-19"){
      navigate('/transfer-form/1');
    }else{
    navigate('/transfer-form');
    }
  };

  const filteredPatients = patients.filter((patient) => {
    return ( true
     // (filters.age === '' || patient.age.toString().includes(filters.age)) &&
     // (filters.sex === '' || patient.sex.toLowerCase() === filters.sex.toLowerCase()) &&
     // (filters.condition === '' || patient.conditions.toLowerCase().includes(filters.condition.toLowerCase()))
    );
  });

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Filter Drawer */}
      <Box sx={{ width: 240, padding: 2 }}>
  <Card elevation={3}>
    <CardContent>
      <Typography variant="h6" gutterBottom>
        Filters
      </Typography>

      {/* Age Range Filter */}
      <TextField
        label="Min Age"
        name="minAge"
        variant="outlined"
        fullWidth
        margin="normal"
        value={filters.minAge}
        onChange={handleFilterChange}
      />
      <TextField
        label="Max Age"
        name="maxAge"
        variant="outlined"
        fullWidth
        margin="normal"
        value={filters.maxAge}
        onChange={handleFilterChange}
      />

      {/* Sex Filter */}
      <FormControl variant="outlined" fullWidth margin="normal">
        <InputLabel>Sex</InputLabel>
        <Select
          name="sex"
          value={filters.sex}
          onChange={handleFilterChange}
          label="Sex"
        >
          <MenuItem value="">Any</MenuItem>
          <MenuItem value="Male">Male</MenuItem>
          <MenuItem value="Female">Female</MenuItem>
        </Select>
      </FormControl>

      {/* Transfer Type Filter */}
      <FormControl variant="outlined" fullWidth margin="normal">
        <InputLabel>Transfer Type</InputLabel>
        <Select
          name="transferType"
          value={filters.transferType}
          onChange={handleFilterChange}
          label="Transfer Type"
        >
          <MenuItem value="">Any</MenuItem>
          <MenuItem value="Low to High">Low to High</MenuItem>
          <MenuItem value="High to Low">High to Low</MenuItem>
        </Select>
      </FormControl>

      {/* Condition Filter */}
      <TextField
        label="Condition"
        name="condition"
        variant="outlined"
        fullWidth
        margin="normal"
        value={filters.condition}
        onChange={handleFilterChange}
      />

      {/* Hospital Filter */}
      <FormControl variant="outlined" fullWidth margin="normal">
        <InputLabel>Current Hospital</InputLabel>
        <Select
          name="currentHospital"
          value={filters.currentHospital}
          onChange={handleFilterChange}
          label="Current Hospital"
        >
          <MenuItem value="">Any</MenuItem>
          {patients
            .map((patient) => patient.currentHospital)
            .filter((hospital, index, self) => self.indexOf(hospital) === index)
            .map((hospital) => (
              <MenuItem key={hospital} value={hospital}>
                {hospital}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </CardContent>
  </Card>
</Box>
  
      {/* Patient Table */}
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Typography variant="h4" gutterBottom>
          Patients Waiting for Transfer
        </Typography>
  
        {/* Dropdown and Create Listing From Template button */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>Template</InputLabel>
            <Select
              value={selectedTemplate}
              onChange={handleTemplateChange}
              label="Template"
            >
              <MenuItem value="Cardiac Event">Cardiac Event</MenuItem>
              <MenuItem value="Hip Fracture">Hip Fracture</MenuItem>
              <MenuItem value="Covid-19">Covid-19</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCreateFromTemplate}
            disabled={!selectedTemplate}
          >
            Create Listing From Template
          </Button>
  
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCreateNewListing}
          >
            Create New Listing
          </Button>
        </Box>
  
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Patient Info</TableCell>
                <TableCell>Vitals & Conditions</TableCell>
                <TableCell>Current Hospital</TableCell>
                <TableCell>Transfer Type</TableCell>
                <TableCell>Select</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredPatients.map((patient) => (
                <TableRow key={patient.id}>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      {/* Bed Type Icon */}
                      <img
                        src={patient.bedType}
                        alt="Bed Type"
                        style={{ width: 24, height: 24, marginRight: 8 }}
                      />
                      {/* Special Status Icon */}
                      <img
                        src={patient.specialStatus}
                        alt="Special Status"
                        style={{ width: 24, height: 24, marginRight: 8 }}
                      />
                      {/* Patient Info */}
                      <Box>
                        <Typography variant="body1">
                          Age: {patient.age} - Status: {patient.status}
                        </Typography>
                        <Typography variant="body2">
                          Height: {patient.height} cm, Weight: {patient.weight} kg, Sex: {patient.sex}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">Vitals: {patient.vitals}</Typography>
                    <Typography variant="body2">Conditions: {patient.conditions}</Typography>
                  </TableCell>
                  <TableCell>{patient.currentHospital}</TableCell>
                  <TableCell>{patient.transferType}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSelectPatient(patient.id)}
                      disabled={selectedPatients.includes(patient.id)}
                    >
                      {selectedPatients.includes(patient.id) ? 'Selected' : 'Select'}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
  
};

export default PatientTransferPage;
