import React, {useState} from 'react';
import {
  Button,
  TableCell,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableContainer,
  TableHead,
  Select,
  MenuItem,InputLabel,
  FormControl,
  Box,
  Typography
} from '@mui/material';

import CircleIcon from "@mui/icons-material/Circle";


const mockPatients = [
  { id: 1, name: "John Doe", age: 45, sex: "Male", reason: "Heart Attack", status: "red", currentHospital: "General Hospital", transferStage: "Reviewing", ach: "L -> H", timeInDock: "02:15", trip: "One-Way", notes: "High BMI, Cardiac Monitoring", modeOfTransport: "Ambulance", servicesRequired: "Cardiology, ICU Bed" },
  { id: 2, name: "Jane Smith", age: 60, sex: "Female", reason: "Pneumonia", status: "yellow", currentHospital: "City Medical Center", transferStage: "Finding Bed", ach: "H -> L", timeInDock: "01:30", trip: "Round Trip", notes: "Isolation Required, Oxygen Support", modeOfTransport: "Rotary Wing", servicesRequired: "Pulmonology, Ventilator Support" },
  { id: 3, name: "Michael Johnson", age: 32, sex: "Male", reason: "Car Accident", status: "green", currentHospital: "County Hospital", transferStage: "In Transport", ach: "Lateral", timeInDock: "00:45", trip: "One-Way", notes: "Orthopedic Consultation, Trauma Care", modeOfTransport: "Fixed Wing", servicesRequired: "Orthopedics, Trauma Surgery" },
  { id: 4, name: "Alice Brown", age: 55, sex: "Female", reason: "Stroke", status: "red", currentHospital: "Downtown Medical", transferStage: "Finding Bed", ach: "L -> H", timeInDock: "03:00", trip: "One-Way", notes: "Speech Therapy Needed", modeOfTransport: "Ambulance", servicesRequired: "Neurology, Stroke Unit" },
  { id: 5, name: "Robert Wilson", age: 70, sex: "Male", reason: "COPD Exacerbation", status: "yellow", currentHospital: "Regional Hospital", transferStage: "Reviewing", ach: "H -> L", timeInDock: "02:10", trip: "Round Trip", notes: "Needs BiPAP Support", modeOfTransport: "Medicab", servicesRequired: "Pulmonology, Respiratory Therapy" },
  { id: 6, name: "Emily Davis", age: 40, sex: "Female", reason: "Sepsis", status: "red", currentHospital: "East Side Hospital", transferStage: "Finding Bed", ach: "Lateral", timeInDock: "01:20", trip: "One-Way", notes: "Septic Shock Monitoring", modeOfTransport: "Ambulance", servicesRequired: "Infectious Disease, ICU" },
  { id: 7, name: "William Harris", age: 29, sex: "Male", reason: "Severe Burns", status: "red", currentHospital: "County Trauma Center", transferStage: "Reviewing", ach: "L -> H", timeInDock: "00:55", trip: "One-Way", notes: "Skin Grafting Needed", modeOfTransport: "Fixed Wing", servicesRequired: "Burn Unit, Plastic Surgery" },
  { id: 8, name: "Olivia Martin", age: 50, sex: "Female", reason: "Kidney Failure", status: "yellow", currentHospital: "City Medical", transferStage: "Finding Bed", ach: "H -> L", timeInDock: "02:45", trip: "Round Trip", notes: "Dialysis Required", modeOfTransport: "Rotary Wing", servicesRequired: "Nephrology, Dialysis Unit" },
  { id: 9, name: "James Anderson", age: 38, sex: "Male", reason: "Spinal Cord Injury", status: "green", currentHospital: "Neuro Rehab Center", transferStage: "In Transport", ach: "Lateral", timeInDock: "01:15", trip: "One-Way", notes: "Paralysis Rehab Support", modeOfTransport: "Ambulance", servicesRequired: "Neurosurgery, Rehabilitation" },
  { id: 10, name: "Sophia Thomas", age: 65, sex: "Female", reason: "Hip Fracture", status: "yellow", currentHospital: "Orthopedic Specialty Clinic", transferStage: "Reviewing", ach: "H -> L", timeInDock: "02:05", trip: "Round Trip", notes: "Post-Surgery Follow-up", modeOfTransport: "Medicab", servicesRequired: "Orthopedics, Physical Therapy" }
];
const getStatusColor = (status) => {
  switch (status) {
    case "red":
      return "#D32F2F";
    case "yellow":
      return "#FBC02D";
    case "green":
      return "#388E3C";
    default:
      return "gray";
  }
};

const getUniqueValues = (key) => [...new Set(mockPatients.map(patient => patient[key]))];

const PatientTransferQueue = () => {
  const [filters, setFilters] = useState({ transferStage: "", trip: "", servicesRequired: "" });

  const handleFilterChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  const filteredPatients = mockPatients.filter(patient =>
    (filters.transferStage === "" || patient.transferStage === filters.transferStage) &&
    (filters.trip === "" || patient.trip === filters.trip) &&
    (filters.servicesRequired === "" || patient.servicesRequired.includes(filters.servicesRequired))
  );
  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Inbound Transfer Queue
      </Typography>
      
      <Box display="flex" gap={2} mb={2}>
        {['transferStage', 'trip', 'servicesRequired'].map((key) => (
          <FormControl key={key} variant="outlined" size="small" style={{ minWidth: 200 }}>
            <InputLabel>{key.replace(/([A-Z])/g, ' $1').trim()}</InputLabel>
            <Select name={key} value={filters[key]} onChange={handleFilterChange} label={key.replace(/([A-Z])/g, ' $1').trim()}>
              <MenuItem value="">All</MenuItem>
              {getUniqueValues(key).map(value => (
                <MenuItem key={value} value={value}>{value}</MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}
      </Box>
      
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Patient Name</TableCell>
              <TableCell>Age</TableCell>
              <TableCell>Sex</TableCell>
              <TableCell>Reason for Hospitalization</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Current Hospital</TableCell>
              <TableCell>Transfer Stage</TableCell>
              <TableCell>ACH</TableCell>
              <TableCell>Time in Dock</TableCell>
              <TableCell>Trip</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Mode of Transport</TableCell>
              <TableCell>Services Req'd</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPatients.map((patient) => (
              <TableRow key={patient.id}>
                <TableCell>{patient.name}</TableCell>
                <TableCell>{patient.age}</TableCell>
                <TableCell>{patient.sex}</TableCell>
                <TableCell>{patient.reason}</TableCell>
                <TableCell>
                  <CircleIcon style={{ color: getStatusColor(patient.status) }} />
                </TableCell>
                <TableCell>{patient.currentHospital}</TableCell>
                <TableCell>{patient.transferStage}</TableCell>
                <TableCell>{patient.ach}</TableCell>
                <TableCell>{patient.timeInDock}</TableCell>
                <TableCell>{patient.trip}</TableCell>
                <TableCell>{patient.notes}</TableCell>
                <TableCell>{patient.modeOfTransport}</TableCell>
                <TableCell>{patient.servicesRequired}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" size="small" style={{ marginRight: 5 }}>View</Button>
                  <Button variant="contained" color="secondary" size="small">Edit</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PatientTransferQueue;
