import './App.css';
import Home from "./Pages/home";
import Contact from "./Pages/contact";
import TriggerPage from "./Pages/trigger-page"
import UnitPage from "./Pages/units"
import UnitDetailsPage from "./Pages/unit-details-page"
import Auth from "./Pages/auth"
import Demo from "./Demo"
import Dashboard from './Pages/dashboard';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScannerPage from './Pages/patient-scanner-page';
import HospitalMap from './Pages/map-page'
import PatientTransferPage from './Pages/transfer-list'
import TransferRequestForm from './Pages/transfer-form'
import HospitalList from './Pages/Hospital-List';
import TransportList from './Pages/Transport-List';
import HospitalAdmin  from './Pages/hospital-admin';
import TransferReceivePage  from './Pages/transfer-receive';
import Coltrain  from './Pages/coltrain';
import BookTransportPage  from './Pages/book-transport';
import InboundQueue  from './Pages/Inbound-Queue';

const pageWrapper = {
  "padding-top":"80px",
  "padding-left": "80px",
  "background-color": '#f2f3f5',
  'height' : '1200px'
}


const pageWrapperClear = {
  "padding-top":"80px",
  "padding-left": "0px",
}
//  <Route path="/transfers" element={<HospitalMap />} />
      

function App(props) {
  const queryParameters = new URLSearchParams(window.location.search)
  const emr = queryParameters.get("emr")
  return (
    
    <BrowserRouter>

     {emr ? '' :  <Demo client={props.client} />}
      <div style={emr ? pageWrapperClear : pageWrapper}>
      <Routes>
        <Route path="/feed" element={<Contact />} />
        <Route path="/home" element={<Contact />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/oldfeed" element={<Home client={props.client} />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/triggers" element={<TriggerPage />} />
        <Route path="/units" element={<UnitPage />} />
        <Route path="/unit-details" element={<UnitDetailsPage />} />
        <Route path="/patient-scanner" element={<ScannerPage />} />
        <Route path="/transfers" element={<HospitalMap />} />
        <Route path="/transfer-list" element={<PatientTransferPage />} />
        <Route path="/transfer-form" element={<TransferRequestForm />} />
        <Route path="/transfer-form/:id" element={<TransferRequestForm />} />
        <Route path="/hospital-list" element={<HospitalList />} />
        <Route path="/transport-list" element={<TransportList />} />
        <Route path="/location-admin" element={<HospitalAdmin />} />
        <Route path="/location-admin/:id" element={<HospitalAdmin />} />
        <Route path="/transfer-receive" element={<TransferReceivePage />} />
        <Route path="/coltrain-case" element={<Coltrain />} />
        <Route path="/book-transport" element={<BookTransportPage />} />
        <Route path="/inbound-queue" element={<InboundQueue />} />

      </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
