import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Avatar } from '@mui/material';
import './App.css'
import { deepOrange } from '@mui/material/colors';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ListIcon from '@mui/icons-material/List';
import GroupsIcon from '@mui/icons-material/Groups';
import FeedbackIcon from '@mui/icons-material/Feedback';
import DashboardIcon from '@mui/icons-material/Dashboard'
import MapIcon from '@mui/icons-material/Map'

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [patient, setPatient] = React.useState({});
  if (!patient.id && props.client) {
    props.client.patient.read().then((data) => {
      setPatient(data);
    });
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const launchAuth = (platform) => {
    var //width = 780,
      height = 550,
      //  left = (window.width - width) / 2,
      top = (window.height - height) / 2;
    //  uniqueWindowId = "authorization-8c9eb657-f7b2-4888-a48a-611070ddc4ee",
    //params,
    //location;
    if (top > 20) {
      top = top - 20;
    }
    //  params = 'width=' + width + ', height=' + height;
    // params += ', top=' + top + ', left=' + left;
    // params += 'titlebar=no, location=yes';

    var location = "https://greenfield-prod-apis.meditech.com/oauth/authorize?client_id=SaphurHealth%40ddaffb6618b94ad5b982487bfb004507&response_type=code&redirect_uri=https%3A%2F%2Flocalhost%3A44304%2FAuth%2FSmart&state=hello&scope=patient%2F%2A.read";
      if(platform.toLowerCase().indexOf('cerner') >= 0){
        location = build_oauth_request_url();
      }
    window.open(location, "", "width=300, height=300");

  }
  

    const build_oauth_request_url = () => {
      var audience = "aud=https%3A%2F%2Ffhir-ehr-code.cerner.com%2Fr42%2Fec2458f2-1e24-41c8-b71b-0e701af7583d%2F"
      var redirectUrl = "&redirect_uri=https%3A%2F%2Flocalhost%3A44304%2FAuth%2FSmart"
  
      var client = "&client_id=8c9eb657-f7b2-4888-a48a-611070ddc4ee"
      var scope = "&scope=user%2FObservation.read%20user%2FMedicationHistory.read"
      var responseType = "&response_type=code"
      var state = "&state=12345"
      const url = "https://authorization.cerner.com/tenants/ec2458f2-1e24-41c8-b71b-0e701af7583d/protocols/oauth2/profiles/smart-v1/personas/provider/authorize?"
      return url + audience + redirectUrl + client + scope + responseType + state;
    }

  /* window.addEventListener("message", function (e) {
     //var oauthMessage = e.data;
     loginWindow.close();
     // processOAuthMessage(oauthMessage);
   }, false);
 */

  const getIcon = (name) => {
    switch (name) {
      case 'Dashboard':
        return <DashboardIcon />
      case 'Feed':
        return <ListIcon />
      case 'Triggers':
        return <FeedbackIcon />
      case 'Units':
      case 'Patient-Scanner':
        return <GroupsIcon />
      case 'Transfers':
          return <MapIcon />
        case 'Inbound-Queue':
              return <LocalHospitalIcon />
      case 'Transfer-List':
          return <ListIcon />
      default:
        return <InboxIcon />
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const loginOpen = Boolean(anchorEl);
   
  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };

  
  const handleClose = (platform) => {
    launchAuth(platform);
    setAnchorEl(null);
};

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar sx={{ bgcolor: "gray" }} position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            C.A.R.T.
          </Typography>
          <Typography sx={{
            marginLeft: 15
          }} variant="p" noWrap component="div">
            {(patient && patient.name && patient.name.length) ? "Patient:" + patient.name[0].given[0] + " " + patient.name[0].family[0] : ""}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader onClick={handleClick}>
          <Avatar sx={{ bgcolor: deepOrange[500] }}>J</Avatar>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={loginOpen}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => handleClose('cerner')}><img alt="Epic Login" className='login-img' src="https://upload.wikimedia.org/wikipedia/commons/3/3f/2011.Cerner.logo.png"/> Login</MenuItem>
            <MenuItem onClick={() => handleClose('meditech')}> <img alt="Epic Login" className='login-img' src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/Meditech-logo.svg/2560px-Meditech-logo.svg.png"/> Login</MenuItem>
            <MenuItem onClick={() => handleClose('meditech')}><img alt="Epic Login" className='login-img' src="https://1000logos.net/wp-content/uploads/2023/04/Epic-Systems-Logo.png"/>  Login</MenuItem>
          </Menu>
          <Typography className='avatar-title' variant="h6">JBH Admin</Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {['Feed', 'Dashboard', 'Triggers', 'Units', 'Patient-Scanner', 'OldFeed', 'Transfers','Transfer-List',"Hospital-List", "Transport-List","Inbound-Queue"].map((text, index) => (
            <ListItemButton
              key={text}

              component="a" href={'/' + text.toLowerCase()}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {getIcon(text)}
              </ListItemIcon>
              <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          ))}
        </List>
        <Divider />

      </Drawer>

    </Box>
  );
}
