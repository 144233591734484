import { Card, CardContent, Typography, Grid, Stepper, Step, StepLabel } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const BookTransportPage = () => {
  const steps = ['Requested', 'Reviewing', 'Accepted', 'Allocating Bed', 'Book Transport', 'In Transfer'];
  const currentStep = 4; // Example: Patient is currently at "Accepted"
  const navigate = useNavigate();

  const handleReceive = () => {
    navigate('/transfer-receive');
  };

  // Fix the default icon issue in Leaflet
  delete L.Icon.Default.prototype._getIconUrl;

  L.Icon.Default.mergeOptions({
    iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a3/Blue_ambulance_icon.svg/2560px-Blue_ambulance_icon.svg.png',//'https://cdn-icons-png.flaticon.com/512/2920/2920297.png', // Replace with your transport icon URL
    iconSize: [25, 25],
    shadowSize: [0, 0],
    iconAnchor: [0, 0]
  });



  const transportIcon = L.icon({
    iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a3/Blue_ambulance_icon.svg/2560px-Blue_ambulance_icon.svg.png',//'https://cdn-icons-png.flaticon.com/512/2920/2920297.png', // Replace with your transport icon URL
    iconSize: [25, 20],
    iconAnchor: [0, 0],
    popupAnchor: [0, -32]
  });



  const startIcon = L.icon({
    iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/f/fb/Map_pin_icon_green.svg',//'https://cdn-icons-png.flaticon.com/512/2920/2920297.png', // Replace with your transport icon URL
    iconSize: [20, 30],
    iconAnchor: [0, 0],
    popupAnchor: [0, -32]
  });

  const transportData =
  {
    "COMPANY": "LifeLine Ambulance Station 1 - HQ",
    "ADDRESS": "501 North Wenatchee Ave, Wenatchee WA 98801",
    "CAPABILITY": "EMS",
    "PHONE": "509-663-8091",
    "HOURS": "24hour Ambulance and Wheelchair Transport Request",
    "Population Served": null,
    "SERVICES": "ALS",
    "SERVICE AREA": null,
    "OXYGEN": null,
    "Pay Source": null,
    "NOTES": "17 ambulances, five 4-wheel drive response units, and 2 wheelchair transport van",
    "latitude": 47.4310657,
    "longitude": -120.3161653
  }
  const hospitalData =  {
    "id": "299c4d05-b6e4-4754-93a2-927ffe40dfd1",
    "name": "Samaritan Hospital",
    "address": "801 E WHEELER RD, MOSES LAKE WA 98837",
    "locationType": "ACH Urban Teach",
    "state": "WA",
    "county": "Grant",
    "city": "Moses Lake",
    "region": "North Central",
    "mgmt": "Public Hospital District",
    "locationSize": "Large",
    "acuteBeds": "37",
    "icuBeds": "12",
    "psychBeds": "",
    "inpatientBeds": "Talk to HR 765 7606 - HR",
    "cduAtc": "",
    "swingBeds": "",
    "otherBeds": "",
    "licensedBeds": "50",
    "availableBeds": "49",
    "traumaDesignation": "III",
    "cardiacLevel": "",
    "strokeLevel": "",
    "heliPadForAirTransport": "",
    "system": "",
    "departmentsAndSpecialties": "",
    "laboratoryServices": "EPIC - Providence EMR",
    "imagingServices": "Family Medicine, Orthopedics, Pediatric, OB/Gyn, Urology,  Gastroenteology",
    "onsiteBoardCertifiedRadiologist": "",
    "familyMedicineServices": "X-ray|Fluoroscopy|Ultrasound|Computed Tomography (CT)|Magnetic Resonance Imaging (MRI)|Nuclear Medicine|Echocardiography|Bone Densitometry (DXA)|3D Mammography|Needle Biopsy|Interventional Procedures",
    "telemedicineServices": "",
    "rehabServices": "",
    "surgicalExpertise": "",
    "medicalExpertise": "Partial list:\r\nPrimary care\r\nPreventive health\r\nSame-day visits\r\nSports medicine\r\nExercise and diet counseling\r\nChronic disease management\r\nDermatology\r\nBehavioral health",
    "erMedicalHours": "",
    "generalClinicHours": "Laparoscopies\r\nMinimally invasive procedures\r\nBreast procedures\r\nHepatobiliary (liver, gallbladder, bile ducts)\r\nAppendectomies\r\nHernia repairs\r\nGall bladder removals\r\nThyroid procedures\r\nGastrointestinal procedures (anti-reflux)\r\nColorectal surgeries (IBS, IBD, diverticulitis, colon cancer)\r\nColonoscopies\r\nEndoscopies\r\nEmergency surgeries",
    "walkInClinicHours": "",
    "pedClinicHours": "Open 24 Hours",
    "pharmacyHours": "",
    "anticoagClinic": "",
    "therapyServicesHours": "",
    "eyeCareHours": "",
    "dentalHours": "",
    "labHours": "",
    "radiologyHours": "",
    "psychiatricHours": "",
    "wicHours": "M-F 6am-6pm, Sat 9am-12pm, 24/7 STAT testing available",
    "telemedicineHours": "M-F 7:30am-5pm",
    "rehabNumber": "",
    "phoneNumber": "",
    "faxNumber": "M-F 8am-5pm",
    "surgeryNumber": "",
    "transferNumber": "(509) 765-5606",
    "telemedicineNumber": "(509) 764-6507",
    "waStateHospAssocMember": "509.793.9770",
    "columbiaBasinHeatlhAssociation": "",
    "otherHospitalAmenities": "509.793.9780",
    "email": "YES",
    "notes": "Grand Columbia Health Alliance",
    "phoneNotes": "",
    "latitude": 47.13,
    "longitude": -119.27,
    "contacts": [],
    "services": [
        {
            "id": "0de73b0e-d741-4bd0-b369-5fb2d35811ad",
            "hospitalLocationId": "299c4d05-b6e4-4754-93a2-927ffe40dfd1",
            "name": "Interventional Procedures",
            "specialty": "Imaging Services",
            "description": null,
            "tag": null
        },
        {
            "id": "2b7c59a1-5f84-4ecf-8c5c-5f6323c69837",
            "hospitalLocationId": "299c4d05-b6e4-4754-93a2-927ffe40dfd1",
            "name": "X-ray",
            "specialty": "Imaging Services",
            "description": null,
            "tag": null
        },
        {
            "id": "4aa86cfe-c9d5-4a0b-a2d9-665b6d71e20f",
            "hospitalLocationId": "299c4d05-b6e4-4754-93a2-927ffe40dfd1",
            "name": "Needle Biopsy",
            "specialty": "Imaging Services",
            "description": null,
            "tag": null
        },
        {
            "id": "4eb5bbb1-8a82-42f6-b2eb-f9dea2c080c3",
            "hospitalLocationId": "299c4d05-b6e4-4754-93a2-927ffe40dfd1",
            "name": "Magnetic Resonance Imaging (MRI)",
            "specialty": "Imaging Services",
            "description": null,
            "tag": null
        },
        {
            "id": "50dd00ee-2757-43cb-bac5-bb10c76f1a13",
            "hospitalLocationId": "299c4d05-b6e4-4754-93a2-927ffe40dfd1",
            "name": "Bone Densitometry (DXA)",
            "specialty": "Imaging Services",
            "description": null,
            "tag": null
        },
        {
            "id": "6a035e77-ab8b-4e42-b1a8-18f8e6bbc2f5",
            "hospitalLocationId": "299c4d05-b6e4-4754-93a2-927ffe40dfd1",
            "name": "Ultrasound",
            "specialty": "Imaging Services",
            "description": null,
            "tag": null
        },
        {
            "id": "9d0bd806-1883-4bf1-9fd8-1cf6ffefaebc",
            "hospitalLocationId": "299c4d05-b6e4-4754-93a2-927ffe40dfd1",
            "name": "Nuclear Medicine",
            "specialty": "Imaging Services",
            "description": null,
            "tag": null
        },
        {
            "id": "a0a4f659-c140-4f0c-ae9f-51e7d2a4d1a1",
            "hospitalLocationId": "299c4d05-b6e4-4754-93a2-927ffe40dfd1",
            "name": "Fluoroscopy",
            "specialty": "Imaging Services",
            "description": null,
            "tag": null
        },
        {
            "id": "be0f6911-d36d-42ab-b263-82d17dfbc363",
            "hospitalLocationId": "299c4d05-b6e4-4754-93a2-927ffe40dfd1",
            "name": "Computed Tomography (CT)",
            "specialty": "Imaging Services",
            "description": null,
            "tag": null
        },
        {
            "id": "c4424b3c-5c89-4e97-8552-2f6d0c918638",
            "hospitalLocationId": "299c4d05-b6e4-4754-93a2-927ffe40dfd1",
            "name": "Family Medicine, Orthopedics, Pediatric, OB/Gyn, Urology,  Gastroenteology",
            "specialty": "Departments and Specialtys",
            "description": null,
            "tag": null
        },
        {
            "id": "ce10de79-d677-4933-a3e3-18e35288c526",
            "hospitalLocationId": "299c4d05-b6e4-4754-93a2-927ffe40dfd1",
            "name": "3D Mammography",
            "specialty": "Imaging Services",
            "description": null,
            "tag": null
        },
        {
            "id": "fb70ca77-808a-4464-8635-f05d74eaab95",
            "hospitalLocationId": "299c4d05-b6e4-4754-93a2-927ffe40dfd1",
            "name": "Echocardiography",
            "specialty": "Imaging Services",
            "description": null,
            "tag": null
        }
    ]
}
  const receivingHospitalData = {
    "id": "2",
    "name": "Confluence Health Hospital",
    "address": "1201 S Miller St Wenatchee WA",
    "locationType": null,
    "state": null,
    "county": null,
    "city": null,
    "region": null,
    "mgmt": null,
    "locationSize": null,
    "acuteBeds": null,
    "icuBeds": null,
    "psychBeds": null,
    "inpatientBeds": null,
    "cduAtc": null,
    "swingBeds": null,
    "otherBeds": null,
    "licensedBeds": null,
    "availableBeds": null,
    "traumaDesignation": null,
    "cardiacLevel": null,
    "strokeLevel": null,
    "heliPadForAirTransport": null,
    "system": null,
    "departmentsAndSpecialties": null,
    "laboratoryServices": null,
    "imagingServices": null,
    "onsiteBoardCertifiedRadiologist": null,
    "familyMedicineServices": null,
    "telemedicineServices": null,
    "rehabServices": null,
    "surgicalExpertise": null,
    "medicalExpertise": null,
    "erMedicalHours": null,
    "generalClinicHours": null,
    "walkInClinicHours": null,
    "pedClinicHours": null,
    "pharmacyHours": null,
    "anticoagClinic": null,
    "therapyServicesHours": null,
    "eyeCareHours": null,
    "dentalHours": null,
    "labHours": null,
    "radiologyHours": null,
    "psychiatricHours": null,
    "wicHours": null,
    "telemedicineHours": null,
    "rehabNumber": null,
    "phoneNumber": null,
    "faxNumber": null,
    "surgeryNumber": null,
    "transferNumber": null,
    "telemedicineNumber": null,
    "waStateHospAssocMember": null,
    "columbiaBasinHeatlhAssociation": null,
    "otherHospitalAmenities": null,
    "email": null,
    "notes": null,
    "phoneNotes": null,
    "latitude": 47.41,
    "longitude": -120.32,
    "contacts": [
      {
        "id": "C4",
        "hospitalLocationId": "2",
        "name": "Dr. Mike Johnson",
        "position": "Orthopedic Surgeon",
        "email": "mike.johnson@hospital2.com",
        "telephone": "(509) 555-0201",
        "officeHours": "8 AM - 4 PM"
      },
      {
        "id": "C5",
        "hospitalLocationId": "2",
        "name": "Radiology Department",
        "position": "Department",
        "email": "radiology@hospital2.com",
        "telephone": "(509) 555-0202",
        "officeHours": "7 AM - 7 PM"
      },
      {
        "id": "C6",
        "hospitalLocationId": "2",
        "name": "Dr. Sarah Lee",
        "position": "Pulmonologist",
        "email": "sarah.lee@hospital2.com",
        "telephone": "(509) 555-0203",
        "officeHours": "9 AM - 5 PM"
      }
    ],
    "services": []
  }

  return (
    <div>
      {/* Timeline (Step Tracker) */}
      <Stepper activeStep={currentStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step onClick={handleReceive} key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {/* Main Layout: Left Column (Patient Info, Needs, Physician), Right Column (Map) */}
      <Grid container spacing={2}>
        {/* Left Column */}
        <Grid item xs={12} md={6}>
          {/* Patient Info Card */}
          <Card style={{ marginBottom: '20px' }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Patient Info
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Name:
                  </Typography>
                  <Typography variant="body1">John Doe</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Date of Birth:
                  </Typography>
                  <Typography variant="body1">01/01/1980</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Status:
                  </Typography>
                  <Typography variant="body1">Stable</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Insurance:
                  </Typography>
                  <Typography variant="body1">ABC Health</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Current Unit:
                  </Typography>
                  <Typography variant="body1">ICU</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Requesting Physician:
                  </Typography>
                  <Typography variant="body1">Dr. Jane Smith</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* Patient Needs & Vitals Row */}
          <Grid container spacing={2} style={{ marginBottom: '20px' }}>
            <Grid item xs={12} sm={6} style={{ display: 'flex' }}>
              <Card style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <CardContent style={{ flexGrow: 1 }}>
                  <Typography variant="h5">History</Typography>
                  <Typography>MRI</Typography>
                  <Typography>Cardio</Typography>
                  <Typography>Other medical procedures as required</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} style={{ display: 'flex' }}>
              <Card style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <CardContent style={{ flexGrow: 1 }}>
                  <Typography variant="h5">Transfer Request</Typography>
                  <Typography>SNF</Typography>
                  <Typography>ICU Nurse Transport</Typography>
                  
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Accepting Physician Card */}
          <Card>
            <CardContent>
              <Typography variant="h5">Activity Log</Typography>
              <table>
                <tr>
                  <td>Patient Received</td>
                  <td>2/15/25 10:45am</td>
                </tr>
                <tr>
                  <td>Vitals</td>
                  <td>14:06</td>
                </tr>
                <tr>
                  <td>Cardiogenic Shock</td>
                  <td>15:25</td>
                </tr>
                <tr>
                  <td>Impella Removed</td>
                  <td> L ventricular wall repaired </td>
                </tr>
              </table>
            
            </CardContent>
          </Card>
        </Grid>

        {/* Right Column - Map */}
        <Grid item xs={12} md={6}>
          <Card style={{ height: '100%' }}>
            <CardContent>
              <MapContainer center={[47.751076, -120.740135]} zoom={7} style={{ height: '80vh', width: '100%' }}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />

                <Marker icon={transportIcon} key={transportData.id} position={[transportData.latitude, transportData.longitude]}>
                  <Popup>
                    <b>{transportData.COMPANY}</b>
                    <br />
                    {transportData.ADDRESS}
                  </Popup>
                </Marker>

                <Marker key={hospitalData.id} position={[hospitalData.latitude, hospitalData.longitude]} icon={startIcon}>
                  <Popup>
                    <b>{hospitalData.name}</b>
                    <br />
                    {hospitalData.address}
                  </Popup>
                </Marker>

                
                <Marker key={receivingHospitalData.id} position={[receivingHospitalData.latitude, receivingHospitalData.longitude]}>
                  <Popup>
                    <b>{receivingHospitalData.name}</b>
                    <br />
                    {receivingHospitalData.address}
                  </Popup>
                </Marker>
              </MapContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>

  );
};

export default BookTransportPage;
